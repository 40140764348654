import styled from '@emotion/styled';
import {
  Accordion,
  Box,
  Container,
  Paper,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from '@mui/material';
import theme from '../../../../theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Link from '@components/common/Link';
import { Z_INDEX } from '@utils/Constants';

export const StyledTableLinkRow = styled(Link)(() => ({
  width: '100%',
  display: 'contents',
}));
export const StyledTableBoxRow = styled(Box)(() => ({
  width: '100%',
  display: 'contents',
}));

export const StyledTable = styled(Table)(() => ({
  minWidth: 650,
}));

export const StyledTableRowHeader = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[6],
}));

export const StyledTableContainer = styled(TableContainer)(
  ({
    marginTop,
    borderRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  }) => ({
    borderRadius: borderRadius ?? 20,
    marginTop: marginTop ?? 40,
    borderBottomLeftRadius: borderBottomLeftRadius ?? 20,
    borderBottomRightRadius: borderBottomRightRadius ?? 20,
    boxShadow: '-2px 7px 10px rgba(0, 0, 0, 0.2)',
  })
);
export const StyledTableCell = styled(TableCell)(
  ({
    width,
    padding,
    maxWidth,
    display,
    justifycontent,
    fontWeight,
    textAlign,
    minWidth,
    color,
  }) => ({
    textAlign: textAlign,
    width: width,
    padding: padding,
    maxWidth: maxWidth,
    display: display,
    justifyContent: justifycontent ?? '',
    fontWeight: fontWeight,
    minWidth: minWidth,
    color: color,
  })
);
export const WarningCell = styled(Box)(() => ({
  backgroundColor: theme.palette.common.red,
  width: 8,
  height: 55,
}));
export const WarningManualCell = styled(Box)(({ height = 55 }) => ({
  backgroundColor: '#9283BE',
  width: 8,
  height: height,
}));
export const ErrorOutlineIconRed = styled(ErrorOutlineIcon)(() => ({
  color: theme.palette.common.red,
}));
export const ContainerStateLicense = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TextStatusProcessLicense = styled(Box)(() => ({
  fontWeight: 700,
  paddingLeft: 20,
}));
export const CircleState = styled(Box)(({ color }) => ({
  width: 12,
  height: 12,
  padding: '0',
  borderRadius: '50%',
  backgroundColor: color,
}));

export const StateTextContainer = styled(Box)(() => ({
  paddingLeft: 10,
}));

export const TableFooterStyled = styled(TableFooter)(() => ({
  display: 'contents',
}));

export const FilterTextButton = styled(Box)(() => ({
  paddingLeft: 15,
  fontSize: 16,
  color: theme.palette.common.blue,
  fontWeight: 600,
  fontFamily: 'Roboto',
}));

export const PaperMenuFilter = styled(Paper)(() => ({
  position: 'absolute',
  top: '100%',
  left: -220,
  borderRadius: 10,
  marginTop: '16px',
  padding: '16px',
  width: '250px',
  zIndex: Z_INDEX?.LOWER,
}));

export const AccordionCustom = styled(Accordion)(({ borderRadius }) => ({
  boxShadow: 'none',
  borderRadius: borderRadius ? borderRadius : 0,
  '&:before': {
    display: 'none',
  },
}));

export const ContainerFilters = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 0 20px 0',
}));

export const HeaderFilters = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 10,
}));

export const ContainerTextMenu = styled(Container)(() => ({
  padding: '0 15px',
}));

export const SelectMyTeam = styled(Select)(() => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
}));

export const TableRowMyTeam = styled(TableRow)(() => ({
  cursor: 'pointer',
}));

export const LinkCell = styled(Box)(() => ({
  textDecoration: 'none',
  color: theme.palette.common.black,
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const ContainerLinkMyTeam = styled(Box)(() => ({
  width: '100%',
  height: '55px',
  alignItems: 'center',
  display: 'flex',
}));
